import {
    Routes,
    Route, useLocation
} from "react-router-dom";
import ErrorBoundary from "ErrorBoundary";
import OldRoutes from "./OldRoutes";
import NewRoutes from "./V2/NewRoutes";
import {useEffect} from "react";

declare var gtag: any;

const ApplicationRoutes = () => {

    const location = useLocation();

    useEffect(() => {
        if (!window.location.hostname.includes('localhost')) {
            gtag('send', 'pageview');
            gtag('set', 'page', location.pathname);
        }
    }, [location.pathname])

    return (
        <ErrorBoundary>
            <Routes>
                
                <Route path={"v2/*"} element={<NewRoutes />} />
                
                <Route path={"*"} element={<OldRoutes />} />
            </Routes>
        </ErrorBoundary>
    );
}



export default ApplicationRoutes;
