import {AgreementModel} from "../../../Apis/Models/AgreementModel";
import {ReactElement, useEffect, useState} from "react";
import React from 'react';
import Modal from "../../../Components/V2/Modal";
import {useTranslation} from "react-i18next";
import {Controller, useForm} from "react-hook-form";
import {ControlledInput} from "../../../Components/V2/Input";
import FlexRow from "Components/FlexRow";
import Grid from "Components/V2/Grid";
import Button from "Components/V2/Button";
import useToast from "../../../Hooks/useToast";
import PieChartIconV2 from "../../../Assets/Icons/PieChartIconV2";
import {StyledText} from "../../../Components/V2/Text";
import RadioButtons from "../../../Components/V2/RadioButtons";
import {useSetCustomUsage} from "../../../Apis/CustomUsageApi";

type Props = {
    agreement: AgreementModel;
    trigger: ReactElement;
}
const AgreementCustomUsageModal = ({ agreement, trigger}: Props) => {

    const { t } = useTranslation();

    const [visible, setVisible] = useState<boolean>(false);

    const { reset, control, handleSubmit } = useForm<any>();

    const { mutateAsync: saveCustomUsageMutation, isLoading, isSuccess} = useSetCustomUsage(agreement?.id!);

    const { showSuccessToast } = useToast();

    useEffect(() => {
        if (visible) {
            if (agreement.customAgreementUsages.length > 0) {
                reset({yearlyUsage: agreement.customAgreementUsages[0].yearlyUsage, usageUnit: agreement.customAgreementUsages[0].usageUnit})
            }
        } else {
            reset({})
        }
    }, [reset, visible, agreement]);

    const onSubmit = async (data: { yearlyUsage: number, usageUnit: string}) => {
        await saveCustomUsageMutation(data)

        showSuccessToast(t('onboarding.companyDetailsUpdated'))

        setTimeout(() => {
            setVisible(false)
        }, 2000)
    }

    return (
        <>
            {React.cloneElement(trigger, {onClick: () => setVisible(true)})}

            <Modal visible={visible} title={t('onboarding.editCustomUsage')} onCancel={() => setVisible(false)}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FlexRow justify={"center"}>
                        <Grid gridTemplateColumns={"400px"} rowGap={16}>
                            <PieChartIconV2 />

                        <StyledText type={"Body"} weight={"Bold"} color={"secondary"}>
                            {t('onboarding.customValueInput.headline')}
                        </StyledText>

                        <StyledText type={"Caption"}>
                            {t('onboarding.customValueInput.subheadline')}
                        </StyledText>

                        <ControlledInput
                            control={control}
                            name={"yearlyUsage"}
                            type={"number"}
                            placeholder={t('onboarding.customValueInput.enterUsage')}
                            min={0}
                            rules={{required: true}}
                        />

                        <Controller name={"usageUnit"} control={control} rules={{required: true}}  render={({field, fieldState: { error }}) => (
                            <RadioButtons value={field.value} onChange={(newValue) => field.onChange(newValue)} errors={error} options={[
                                { value: "KWH", label: t('KWH') },
                                { value: "MWH", label: t('MWH') },
                                { value: "GWH", label: t('GWH') },
                            ]} />
                        )} />

                            <FlexRow justify={"end"}>
                                <Button text={t('confirm')} state={isLoading ? 'Loading' : isSuccess ? 'Success' : 'None'} type={"submit"} />
                            </FlexRow>
                        </Grid>
                    </FlexRow>
                </form>
            </Modal>
        </>
    )

}

export default AgreementCustomUsageModal