import styles from './AgreementOnboardingEloverblikPage.module.scss'
import DefaultPageLayout from "Layouts/V2/DefaultPageLayout";
import {useTranslation} from "react-i18next";
import Box from "Components/V2/Box";
import {StyledText} from "../../../Components/V2/Text";
import React, {useEffect, useRef, useState} from "react";
import CheckmarkCircle from "../../../Assets/Icons/CheckmarkCircle";
import Separator from "../../../Components/V2/Separator";
import FlexRow from "../../../Components/FlexRow";
import Gap from 'Components/V2/Gap';
import Button from "../../../Components/V2/Button";
import LightbulbIcon from "../../../Assets/Icons/V2/LightbulbIcon";
import {useAuthorizationUrl} from "../../../Apis/EloverblikAuthorizationApi";
import {useParams} from "react-router-dom";
import {useAgreement} from "../../../Apis/AgreementApi";
import {EloverblikAuthorizationUrl} from "../../../Apis/Models/EloverblikAuthorizationUrl";

const AgreementOnboardingEloverblikPage = () => {

    const { t } = useTranslation();
    const { agreementId } = useParams();
    const newWindowRef = useRef<any>(null);

    const { data: agreement, refetch } = useAgreement(agreementId!);

    const [isWaitingForEloverblik, setIsWaitingForEloverblik ] = useState<boolean>(false);
    const [authorization, setAuthoriszation ] = useState<EloverblikAuthorizationUrl>();

    const { mutateAsync: getAuthorizationUrlMutation, isLoading: isGettingAuthorizationUrl } = useAuthorizationUrl();

    useEffect(() => {
        if (!!agreement && !!authorization && agreement.eloverblikAuthorizations.find(x => x.id === authorization.id)?.status === 'Active') {
            newWindowRef.current.close();
        }
    }, [agreement, authorization]);

    useEffect(() => {
        if (isWaitingForEloverblik) {
            const interval = setInterval(() => {
                refetch();
            }, 5000);

            return () => clearInterval(interval);
        }
    }, [isWaitingForEloverblik, refetch])

    const giveAccess = async () => {
        const authorization = await getAuthorizationUrlMutation({ agreementId: agreement!.id });

        setAuthoriszation(authorization);

        newWindowRef.current = window.open(authorization.url);

        setIsWaitingForEloverblik(true);
    }

    const bulletPoint = (text: string) => (
        <div className={styles.explainerItem}>
            <CheckmarkCircle />
            <StyledText type={'Caption'} color={"secondary"}>
                {text}
            </StyledText>
        </div>
    )

    return (
        <DefaultPageLayout contentMaxWidth={1440}>
            <div className={styles.container}>

                <div className={styles.headline}>
                    <StyledText type={"Headline"} weight={"Bold"} color={"secondary"}>
                        {t('eloverblik.giveAccess')}
                    </StyledText>
                </div>

                <Box style={{maxWidth: 600, width: '100%'}}>
                    <Gap size={"medium"} direction={"vertical"}>
                        <StyledText type={"Body"} weight={"Bold"} color={"secondary"}>
                            {t('eloverblik.header')}
                        </StyledText>
                        <Gap size={"small"} direction={"vertical"}>

                            {bulletPoint(t('eloverblik.bullet.1'))}
                            {bulletPoint(t('eloverblik.bullet.2'))}
                            {bulletPoint(t('eloverblik.bullet.4'))}
                        </Gap>

                        <Separator/>

                        <FlexRow justify={"space-between"}>
                            <Gap size={"small"}>
                                <LightbulbIcon />
                                <StyledText type={"Caption"}>{t('eloverblik.openBlank')}</StyledText>
                            </Gap>

                            <Gap size={"small"}>
                                <Button text={t('continue')} onClick={giveAccess} state={isGettingAuthorizationUrl ? 'Loading' : 'None'} />
                            </Gap>
                        </FlexRow>
                    </Gap>

                </Box>

            </div>
        </DefaultPageLayout>
    )

}

export default AgreementOnboardingEloverblikPage